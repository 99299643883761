import { Injectable } from '@angular/core';
import { DeleteObjectsOutput, GetObjectCommand, GetObjectOutput, PutObjectOutput, S3, S3ClientConfig } from '@aws-sdk/client-s3';
import { S3RequestPresigner } from '@aws-sdk/s3-request-presigner';
import { createRequest } from '@aws-sdk/util-create-request';
import { formatUrl } from '@aws-sdk/util-format-url';
import { Sha256 } from '@aws-crypto/sha256-browser';

import { environment } from '../../../environments/environment';


@Injectable({
	providedIn: 'root',
})
export class FileStorageService {
	private s3Configuration: S3ClientConfig = {
		credentials: {
			accessKeyId: environment.ContentBucketKey,
			secretAccessKey: environment.ContentBucketSecret,
		},
		region: environment.ContentBucketRegion,
	};
	private s3Client: S3 = new S3(this.s3Configuration);
	private s3RequestPresigner: S3RequestPresigner = new S3RequestPresigner({
		credentials: this.s3Configuration.credentials,
		region: this.s3Configuration.region,
		sha256: Sha256,
	});

	private static getS3ObjectKey(payload: string): string {
		return payload?.slice(payload.lastIndexOf('/') + 1);
	}

	public deleteFiles(payload: string): Promise<DeleteObjectsOutput> {
		return this.s3Client.deleteObjects({
			Bucket: environment.ContentBucketName, Delete: {
				Objects: [{ Key: FileStorageService.getS3ObjectKey(payload) }],
			},
		});
	}

	public uploadFile(file: File, fileName?: string): Promise<PutObjectOutput> {
		return this.s3Client.putObject({
			Bucket: environment.ContentBucketName,
			Key: fileName,
			Body: file,
			ContentDisposition: 'attachment',
			ContentType: file.type,
			ServerSideEncryption: 'AES256',
		});
	}

	public async getSignedUrl(payload: string, expirationSeconds: number = 15 * 60): Promise<string> {
		const httpRequest = await createRequest(
			this.s3Client,
			new GetObjectCommand({ Bucket: environment.ContentBucketName, Key: FileStorageService.getS3ObjectKey(payload) }),
		);
		const presignedRequest = await this.s3RequestPresigner.presign(httpRequest, { expiresIn: expirationSeconds });
		return formatUrl(presignedRequest);
	}

	public async getS3Object(objectKey: string): Promise<GetObjectOutput> {
		return this.s3Client.getObject({ Bucket: environment.ContentBucketName, Key: objectKey });
	}
}
