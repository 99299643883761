import { ComponentRef, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { GoogleAnalyticsInitializer, GoogleAnalyticsRouterInitializer, GoogleAnalyticsService, GtagFn, NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, NGX_GTAG_FN } from 'ngx-google-analytics';
import { IGoogleAnalyticsSettings } from 'ngx-google-analytics/lib/interfaces/i-google-analytics-settings';
import { GaActionEnum } from 'ngx-google-analytics/lib/enums/ga-action.enum';

import { DomainEntityTypeName, GQLMutationName } from '@tasktrain/shared';

import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class AnalyticsService {
	private isInitialized: boolean;

	public constructor(
		@Inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN) private googleAnalyticsSettingsToken: IGoogleAnalyticsSettings,
		@Inject(NGX_GTAG_FN) private googleTagFunction: GtagFn,
		@Inject(DOCUMENT) private document: Document,
		private googleAnalyticsService: GoogleAnalyticsService,
	) {
	}

	public initializeGoogleAnalytics(bootstrapComponent: ComponentRef<unknown>): void {
		if (environment.production && !this.isInitialized) {
			Promise.all([
				GoogleAnalyticsInitializer(this.googleAnalyticsSettingsToken, this.googleTagFunction, document)(),
				GoogleAnalyticsRouterInitializer({}, this.googleAnalyticsService)(bootstrapComponent), // @ToDo: Consider restricting to user registration only: `{include: [AuthRoutingPaths.Register]}`
			]).then(() => {
				this.isInitialized = true;
			}).catch((error: unknown) => {
				this.isInitialized = false;
			});
		}
	}

	/** Log Google Analytics Event https://support.google.com/analytics/answer/1033068?hl=en#
	 *
	 * @param action - identifier name for specific user action: https://support.google.com/analytics/answer/9267735?hl=en&ref_topic=9756175
	 * @param category - generic group for related actions
	 * @param label - subject or predicate of action
	 * @param value - numeric value associated with action
	 * @param interaction - whether to include in bounce rate calculations
	 */
	public logAction(
		action: GaActionEnum | GQLMutationName | string,
		category?: DomainEntityTypeName | string,
		label?: string,
		value?: number,
		interaction?: boolean,
	): void {
		if (this.isInitialized) {
			this.googleAnalyticsService.event(action, category, label, value, interaction);
		}
	}

	/** Log application error as Google Analytics exception */
	public logError(description?: string, fatal?: boolean): void {
		if (this.isInitialized) {
			this.googleAnalyticsService.exception(description, fatal);
		}
	}
}
