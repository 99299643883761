import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import { AppModule } from './app/app.module';

if (environment.production) {
	enableProdMode();
}
void platformBrowserDynamic().bootstrapModule(AppModule);
